// terms
import * as colorNames from "./terms/colornames.bestof.esm.js";
import "./terms/colornames.custom.js";
import * as naughtyWords from "./terms/terms.naughty.js";
// responses
import { responses } from "./responses/responses.js";
// utils
import {
  invertColor,
  hexToHSL,
  lightOrDark,
  limitNumberWithinRange,
  getRandomInt,
} from "./utils.js";

function bindColorForm(el) {
  // exported
  el.addEventListener("submit", (e) => {
    e.preventDefault();
    let term = document.getElementById("favorite-color-search").value;
    getColor(term);
  });
}

function bindColorSearchLink(link) {
  link.addEventListener("click", (e) => {
    e.preventDefault();
    const search = link.innerText;
    getColor(search);
  });
}

/*
 * Determine color
 */

function getColor(search, setBar = true) {
  // set the search bar to the current color if it's not already
  if (setBar === true) {
    document.getElementById("favorite-color-search").value = search;
  }

  // clean up the term
  let term = search.toLowerCase().trim();
  if (term == "") {
    return;
  }

  //
  // loop through colors to find a match
  //
  const colors = colorNames.default;
  let foundColor = false,
    loopColorName = "";

  // exact match
  for (var i = 0; i < colors.length; i++) {
    loopColorName = colors[i].name.toLowerCase().trim();
    if (term == loopColorName) {
      foundColor = colors[i];
      foundColor.exact = true;
      break;
    }
  }
  // fuzzy match
  if (foundColor == false) {
    for (var k = 0; k < colors.length; k++) {
      loopColorName = colors[k].name.toLowerCase().trim();
      if (loopColorName.indexOf(term) > -1) {
        foundColor = colors[k];
        foundColor.exact = false;
        break;
      }
    }
  }

  //
  // callbacks
  //
  if (foundColor !== false) {
    console.log(foundColor);
    getColorScheme(foundColor, setBar);
  } else {
    console.log(term);
    noColor(term);
  }
}

function getColorScheme(color, setBar = true) {
  let primary = hexToHSL(color.hex);
  let secondary = hexToHSL(invertColor(color.hex));
  let brightness = lightOrDark(color.hex);
  // adjust secondary color saturation + lightness to blend better with primary
  secondary.s = limitNumberWithinRange(primary.s, 0, 20);
  if (brightness == "light") {
    secondary.l = limitNumberWithinRange(secondary.l, 0, 25);
  } else {
    secondary.l = limitNumberWithinRange(secondary.l, 95, 100);
  }
  // export palette
  let palette = {
    primary: `hsl(${primary.h}, ${primary.s}%, ${primary.l}%)`,
    borderColor: `hsla(${primary.h}, ${primary.s}%, ${primary.l}%, 0.4)`,
    borderColorFull: `hsla(${primary.h}, ${primary.s}%, ${primary.l}%, 0.7)`,
    secondary: `hsl(${secondary.h}, ${secondary.s}%, ${secondary.l}%)`,
    brightness: brightness,
    name: color.name,
  };

  // set palette
  setPalette(palette);
  if (setBar === true) {
    setMessage({
      term: palette.name,
      foundColor: true,
    });
  }
}

function setPalette(palette) {
  let styles = `
  h1, h2, p, li, a {
    color: ${palette.primary};
  }
  a {
    text-decoration-color: ${palette.borderColor} !important;
  }
  a:hover {
	  text-decoration-color: ${palette.borderColorFull} !important;
  }
  #favorite-color input[type="text"],
  #favorite-color input[type="submit"] {
    color: ${palette.primary} !important;
    border-color: ${palette.primary};
  }
  `;
  if (palette.brightness == "light") {
    styles += `
      body {
        background-color: ${palette.secondary};
      }
    `;
  }
  let styleEl = document.querySelector("style.color-palette");
  if (styleEl) {
    styleEl.parentNode.removeChild(styleEl);
  }
  styleEl = document.createElement("style");
  styleEl.setAttribute("type", "text/css");
  styleEl.classList.add("color-palette");
  let styleContent = document.createTextNode(styles);
  styleEl.appendChild(styleContent);
  document.head.appendChild(styleEl);
  document.body.classList.add("custom-colors");
}

function noColor(term) {
  setMessage({
    term: term,
    foundColor: false,
  });
}

/*
 * Write message
 */

let naughtyCount = 0;

function getMessage(obj) {
  let term = obj.term.toLowerCase();
  // did the user use a naughty word?
  for (var a = 0; a < naughtyWords.default.length; a++) {
    let naughtyWord = naughtyWords.default[a];
    if (term.indexOf(naughtyWord) > -1) {
      if (naughtyCount < 4) naughtyCount++;
      return responses.naughty[naughtyCount - 1];
    }
  }
  // specific response
  let specificResponse = false;
  for (var i = 0; i < responses.specific.length; i++) {
    let response = responses.specific[i];
    for (var k = 0; k < response.terms.length; k++) {
      if (response.terms[k] == term) {
        specificResponse = response.response;
        break;
      }
    }
  }
  if (specificResponse) {
    return specificResponse;
  }
  // no correct response yet? use a generic message
  if (obj.foundColor == true) {
    // color was found, just not exact
    let genericIndex = getRandomInt(0, responses.generic.length - 1);
    return responses.generic[genericIndex];
  } else {
    // no color found
    let notFoundIndex = getRandomInt(0, responses.notFound.length - 1);
    return responses.notFound[notFoundIndex];
  }
}

function convertTerms(text) {
  let el = document.createElement("p");
  function matchReplace(_str, group1) {
    return "``" + group1 + "``";
  }
  let linkRegex = /(\[(.*?)\])/g;
  let textFilter = text.replace(linkRegex, matchReplace);
  let nodes = textFilter.split(/``/g);
  for (var i = 0; i < nodes.length; i++) {
    nodeIterator(i);
  }
  function nodeIterator(i) {
    let node = nodes[i];
    let textEl = null;
    let linkMatch = node.match(linkRegex);
    if (linkMatch) {
      linkMatch = linkMatch[0];
      linkMatch = linkMatch.replace(/[\[\]]/g, "");
      textEl = document.createElement("a");
      textEl.setAttribute("href", `#${linkMatch}`);
      textEl.setAttribute("class", "color-shift with-border");
      textEl.innerText = linkMatch;
      bindColorSearchLink(textEl);
    } else {
      textEl = document.createTextNode(node);
    }
    el.appendChild(textEl);
  }
  return el;
}

function setMessage(obj) {
  const response = getMessage(obj);
  const responseEl = convertTerms(response);
  let messageEl = document.querySelector("#color-response");
  messageEl.classList.remove("visible");
  setTimeout(() => {
    messageEl.classList.add("visible");
  }, 50);

  // clear existing
  let existingEl = messageEl.querySelector("p");
  if (existingEl) {
    existingEl.parentNode.removeChild(existingEl);
  }
  messageEl.appendChild(responseEl);

  // if user is mean a lot, send them something stupid
  if (naughtyCount > 3) {
    setTimeout(() => {
      window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
    }, 1000);
  }
}

export { bindColorForm, getColor };
