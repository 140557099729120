export default [
  {
    terms: [`blue`],
    response: `Any kind of blue in particular, though? An understated [ocean], or a vibrant [teal]?`,
  },
  {
    terms: [`red`],
    response: `Okay, but let's get specific. A fiery [scarlet]? A regal [cardinal]?`,
  },
  {
    terms: [`green`],
    response: `Sure, yeah. More like a [jade], or a tasty [lime]?`,
  },
  {
    terms: [`purple`],
    response: `"The thinking man's red," they say. (They don't, but maybe if you start saying it we can make it a thing.)`,
  },
  {
    terms: [`yellow`],
    response: `A lot of people would say red or blue, but yellow? That's for the bon vivants.`,
  },
  {
    terms: [`orange`],
    response: `This was my favorite for a while too — but orange knows what it did.`,
  },
  {
    terms: [`black`],
    response: `Yeah, but what shade of black? [Obsidian]? [Onyx]? [Midnight]?`,
  },
  {
    terms: [`teal`, `sky`, `ocean`, `ocean blue`],
    response: `A particularly good blue.`,
  },
  {
    terms: [
      `clementine`,
      `banana`,
      `coffee`,
      `tangerine`,
      `peach`,
      `pear`,
      `watermelon`,
      `plum`,
      `lime`,
      `cheese`,
      `chocolate`,
      `vanilla`,
      `pumpkin`,
    ],
    response: `More colors should be named after food.`,
  },
  {
    terms: [`gem`, `gold`, `emerald`, `silver`, `ruby`, `sapphire`, `jade`],
    response: `Oh la la. What are you, a banker?`,
  },
  {
    terms: ["cardinal"],
    response: `All hail bird reds.`,
  },
  {
    terms: ["indigo", "violet"],
    response: `You know what they say: sophisticated purples, sophisticated taste. ...Or something?`,
  },
  {
    terms: ["onyx", "midnight", "obsidian"],
    response: `Yeah, that's more like it.`,
  },
  {
    terms: ["pink"],
    response: `Like the artist, or the wine sporting a similar hue, it really does get the party started.`,
  },
  {
    terms: ["brown", "gray", "ultimate gray", "grey"],
    response: `Some people would say this is a boring choice. But I like that you own it.`,
  },
  {
    terms: ["snow"],
    response: `Look, I got nothing against this, but this answer sounds like you own a summer home.`,
  },
];
