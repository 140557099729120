import * as colorNames from "./colornames.bestof.esm.js";

// change some existing values I don't think are very good
const adjustedColors = [
  {
    name: "Blue",
    hex: "#1579e5",
  },
  {
    name: "Purple",
    hex: "#5a309e",
  },
  {
    name: "Green",
    hex: "#1fc160",
  },
  {
    name: "Yellow",
    hex: "#ffe640",
  },
];

const newColors = [
  {
    name: "Red",
    hex: "#db3232",
  },
];

function getExistingColorIndex(colorName) {
  for (var i = 0; i < colorNames.default.length; i++) {
    var color = colorNames.default[i];
    if (color.name == colorName) {
      return i;
    }
  }
  return false;
}

// add new colors
for (var k = 0; k < newColors.length; k++) {
  colorNames.default.push(newColors[k]);
}

// adjust existing colors
for (var i = 0; i < adjustedColors.length; i++) {
  let color = adjustedColors[i];
  let index = getExistingColorIndex(color.name);
  if (index) {
    colorNames.default[index]["hex"] = color.hex;
  }
}
