import * as generic from "./responses.generic.js";
import * as notFound from "./responses.notFound.js";
import * as naughty from "./responses.naughty.js";
import * as specific from "./responses.specific.js";

const responses = {
  generic: generic.default,
  notFound: notFound.default,
  naughty: naughty.default,
  specific: specific.default,
};

export { responses };
