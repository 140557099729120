import { bindColorForm, getColor } from "./color-search.js";

document.addEventListener("DOMContentLoaded", () => {
  getColor("gold", false);
  setTimeout(() => {
    document.body.classList.add("transition-active");
  }, 200);
  const colorForm = document.querySelector("#favorite-color");
  if (colorForm) {
    bindColorForm(colorForm);
  }
});
